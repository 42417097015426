import React from 'react'

const SvgLearingApp = () => {
  return (
    <svg viewBox='0 0 60 60' width='1em' height='1em'>
      <defs>
        <style>
          {
            '.learing_app_svg__cls-1{fill:#fff}.learing_app_svg__cls-2{fill:#5d6da4}'
          }
        </style>
      </defs>
      <g
        id='learing_app_svg__DESIGNED_BY_FREEPIK'
        data-name='DESIGNED BY FREEPIK'
      >
        <path className='learing_app_svg__cls-1' d='M0 0h60v60H0z' />
        <g id='learing_app_svg__Group_86' data-name='Group 86'>
          <path
            id='learing_app_svg__Path_76'
            data-name='Path 76'
            className='learing_app_svg__cls-2'
            d='M47.89 23.53A17.07 17.07 0 0014 22.28a37.53 37.53 0 01-1.61 6.53l-.29.8a2.21 2.21 0 00.23 2 1.93 1.93 0 001.59.82h.22v6.48a3.49 3.49 0 003.49 3.48H22a2 2 0 012 2V48a2 2 0 002 2h12.24a2 2 0 002-2v-8.33a.62.62 0 01.29-.51 17.15 17.15 0 007.36-15.63z'
          />
          <path
            id='learing_app_svg__Path_77'
            data-name='Path 77'
            className='learing_app_svg__cls-1'
            d='M31 11.53a14.15 14.15 0 00-13.9 12.94 1.2 1.2 0 001.07 1.34h25.48a1.22 1.22 0 001.2-1.24.41.41 0 000-.11A14.15 14.15 0 0031 11.53z'
          />
          <path
            id='learing_app_svg__Path_78'
            data-name='Path 78'
            d='M37 24.36v-10a12.77 12.77 0 00-5.46-1.52v11.52z'
            fill='#98658c'
          />
          <path
            id='learing_app_svg__Path_79'
            data-name='Path 79'
            d='M43.11 23.06a12.72 12.72 0 00-5.21-8.17v9.22h4.32a.9.9 0 00.9-.9.76.76 0 00-.01-.15z'
            fill='#cb3d50'
          />
          <path
            id='learing_app_svg__Path_80'
            data-name='Path 80'
            className='learing_app_svg__cls-2'
            d='M30.5 24.36V12.83a12.76 12.76 0 00-5.5 1.51v10z'
          />
          <path
            id='learing_app_svg__Path_81'
            data-name='Path 81'
            d='M24 14.89a12.7 12.7 0 00-5.22 8.17.89.89 0 00.73 1H24z'
            fill='#5c95d2'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgLearingApp
