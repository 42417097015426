import React from 'react'

const SvgVrApp = () => {
  return (
    <svg viewBox='0 0 60 60' width='1em' height='1em'>
      <g data-name='DESIGNED BY FREEPIK'>
        <path fill='#fff' d='M0 0h60v60H0z' />
        <path
          data-name='Path 89'
          d='M50.05 22.09a4.45 4.45 0 00-4.44-4.37H13a4.47 4.47 0 00-4.45 4.39l-2 15.6v.12A4.46 4.46 0 0011 42.28h9.44a8.17 8.17 0 006.88-3.76l.92-1.44a1.58 1.58 0 012.19-.48 1.38 1.38 0 01.48.48l.93 1.44a8.15 8.15 0 006.87 3.76h9.44a4.46 4.46 0 004.45-4.45zm-29.9 1.4h-2.8a3.1 3.1 0 00-3.07 2.6L13.49 31a.93.93 0 01-.92.78h-.15a.93.93 0 01-.77-1.07l.79-4.87a5 5 0 014.9-4.16h2.8a.93.93 0 110 1.86zm26.62 9.32h-.15a.93.93 0 01-.92-.81l-.79-4.86a3.09 3.09 0 00-3.06-2.6h-2.8a.93.93 0 110-1.86h2.81a4.94 4.94 0 014.9 4.16l.79 4.86a.93.93 0 01-.77 1.07z'
          fill='#916085'
        />
      </g>
    </svg>
  )
}

export default SvgVrApp
