import React, { useEffect, useState } from "react";
import "./AzureComponent.scss";
import MsButton from "../assets/MsSymbollockupSigninLight";
import Communicator from "../assets/Communicator";
import Learing from "../assets/Learning";
import VrApp from "../assets/VrApp";
import ArApp from "../assets/ArApp";
import AzureAuth from "../AzureAuth";
import Logo from "../assets/MCASTLogo";

const Azure: React.FunctionComponent<AzureComponentProps> = ({
  forceLogin,
  children,
}) => {
  const [loggedIn, setLoggedIn] = useState(AzureAuth.isLoggedIn());

  useEffect(() => {
    (async () => {
      if (forceLogin) {
        if (!loggedIn) {
          if (!localStorage.getItem("logout")) {
            AzureAuth.log("try login on load");
            loginHandler();
          }
          localStorage.removeItem("logout");
        } else {
          AzureAuth.log("ALREADY LOGGED IN");
        }
      }
    })();
  }, []);

  const setLogin = () => {
    AzureAuth.log("set login to true");
    setLoggedIn(true);
  };

  const loginHandler = async () => {
    try {
      const resp = await AzureAuth.loginPopup(setLogin);
      AzureAuth.log("login handler", resp);
      if (resp) setLogin();
    } catch (e) {
      setLoggedIn(false);
    }
  };

  if (loggedIn) {
    return children instanceof Function ? children() : children;
  }

  return (
    <div className="AppAzureComponent">
      <div className="holderAzureComponent">
        <Logo className="McastLogoAzureComponent" />

        <h2>
          Welcome to the <strong>OTIS</strong> platform
        </h2>
        <div className="appIconsAzureComponent">
          <Communicator />
          <Learing />
          <VrApp />
          <ArApp />
        </div>
        <h3>
          Sign in with your MCAST credentials and gain access to all the apps
        </h3>
        <button onClick={loginHandler} data-testid="login_button">
          <MsButton />
        </button>
      </div>
    </div>
  );
};

export default Azure;
